import React, { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const HomeView = lazy(() => import('./components/public/home/home.view'));

const LoginView = lazy(() => import('./components/login/login.view'));
const ForgotPasswordView = lazy(() => import("./components/forgot.password/forgot.password.view"));
const VerifyCodeView = lazy(() => import("./components/forgot.password/verify.code.view"));
const RegisterView = lazy(() => import("./components/account.signup/account.signup.view"));

// Storefront/StoreCarty POS
const MerchantStoreView = lazy(() => import('./components/merchant.store/merchant.store.view'));

const App = () => {

  console.log("Init");

  return (
    <Router future={{ v7_startTransition: true }}>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/login" element={<LoginView />} />
        <Route path='/reset-password' element={<ForgotPasswordView />} />
        <Route path='/verify-code' element={<VerifyCodeView />} />
        <Route path='/register' element={<RegisterView />} />

        {/* STOREFRONT/CARTY POS */}
        <Route path='store/*' element={<MerchantStoreView />} />

      </Routes>
    </Router>
  );
}

export default App;
